<template>
  <div>
    <div class="d-flex">
      <feather-icon
        icon="ArrowLeftIcon"
        size="24"
        class="align-middle text-primary mr-2 mb-2 back"
        @click="$router.go(-1)"
      />
      <h3>Edit Mitra</h3>
    </div>
    <form-wizard
      color="#28C76F"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Update Data"
      back-button-text="Kembali"
      next-button-text="Selanjutnya"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        title="Informasi Pribadi"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nama Lengkap"
                label-for="namaLengkap"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Lengkap"
                  rules="required"
                >
                  <b-form-input
                    id="namaLengkap"
                    v-model="userData.nama"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nama Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.emailValue"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email Mitra"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="jeniskelamin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Kelamin"
                  rules="required"
                >
                  <div class="d-flex flex-row">
                    <b-form-radio
                      v-model="userData.jenis_kelamin"
                      plain
                      value="0"
                      class="mr-2"
                    >
                      Laki Laki
                    </b-form-radio>
                    <b-form-radio
                      v-model="userData.jenis_kelamin"
                      plain
                      value="1"
                    >
                      Wanita
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NIK KTP"
                label-for="nik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  rules="required"
                >
                  <b-form-input
                    id="nik"
                    v-model="userData.nik_ktp"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NIK KTP"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Layanan"
                label-for="layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="layanan"
                >
                  <v-select
                    v-model="layanan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="text"
                    :options="vertikaloptions"
                    placeholder="Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Kode Area"
                label-for="area"
              >
                <validation-provider
                  #default="{ errors }"
                  name="area"
                  rules="required"
                >
                  <b-form-input
                    id="area"
                    v-model="userData.layanan[0].area"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Kode Area"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Informasi Kontak"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nomor HP"
                label-for="nomorHP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="nomorHP"
                      v-model="userData.no_hp"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor HP"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor WhatsApp"
                label-for="nomorWhatsApp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor WhatsApp"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="nomorWhatsApp"
                      v-model="userData.no_wa"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor WhatsApp"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat Lengkap"
                label-for="alamat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat Lengkap"
                  rules="required"
                >
                  <b-form-input
                    id="alamat"
                    v-model="userData.alamat"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Alamat Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Provinsi"
                label-for="provinsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Provinsi"
                  rules="required"
                >
                  <b-form-input
                    id="provinsi"
                    v-model="userData.provinsi"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Provinsi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Kota/Kabupaten"
                label-for="kota"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kota"
                  rules="required"
                >
                  <b-form-input
                    id="kota"
                    v-model="userData.kota"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Kota"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Kecamatan"
                label-for="kecamatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kecamatan"
                  rules="required"
                >
                  <b-form-input
                    id="kecamatan"
                    v-model="userData.kecamatan"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Kecamatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Informasi Keluarga"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nomor HP Keluarga"
                label-for="no_hp_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP Keluarga"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="no_hp_keluarga"
                      v-model="userData.keluarga.no_hp_keluarga"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor HP Keluarga"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Keluarga"
                label-for="nama_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Keluarga"
                  rules="required"
                >
                  <b-form-input
                    id="nama_keluarga"
                    v-model="userData.keluarga.nama_keluarga"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nama Keluarga"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Status Keluarga"
                label-for="status_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status Keluarga"
                  rules="required"
                >
                  <b-form-input
                    id="status_keluarga"
                    v-model="userData.keluarga.status_keluarga"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Status Keluarga"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="Data Pendukung"
        :before-change="validationFormSocial"
      >
        <b-alert
          variant="primary"
          show
        >
          <h4 class="alert-heading">
            Penting!
          </h4>
          <div class="alert-body">
            Hanya upload data pendukung yang ingin diubah, jika tidak diubah maka tidak perlu diisi
          </div>
        </b-alert>
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Foto Surat Pernyataan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Surat Pernyataan"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSuratPernyataan"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSuratPernyataan"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="suratPernyataan = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Selfi Bersama Surat Pernyataan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Selfi Bersama Surat Pernyataan"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSelfiSuratPernyataan"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSelfiSuratPernyataan"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="selfiSuratPernyataan = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto KTP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto KTP"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropKTP"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoKTP"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoKTP = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Selfi Bersama KTP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Selfi Bersama KTP"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSelfiKTP"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSelfiKTP"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="selfiKTP = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto SKCK / Surat Pernyataan SKCK Sementara"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto SKCK / Surat Pernyataan SKCK Sementara"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSKCK"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoSKCK"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoSKCK = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Profil"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Profil"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropProfil"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoProfil"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoProfil = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Sertifikat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Sertifikat"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSertifikat"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoSertifikat"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoSertifikat = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BInputGroup,
  BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
/* eslint-disable no-unused-vars */
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vSelect from 'vue-select'
import { db, storage } from '@/firebase'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BInputGroup,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vueDropzone: vue2Dropzone,
    vSelect,
  },
  props: ['id'],
  data() {
    return {
      vertikaloptions: [
        { value: 'vl01', text: 'Akupunktur' },
        { value: 'vl02', text: 'Auto' },
        { value: 'vl03', text: 'Barber' },
        { value: 'vl04', text: 'Bekam' },
        { value: 'vl05', text: 'Beauty' },
        { value: 'vl06', text: 'Cleaning' },
        { value: 'vl07', text: 'Massage' },
        { value: 'vl08', text: 'Cuci AC' },
      ],
      userData: {
        layanan: [{}],
        keluarga: {

        },
      },
      nama: '',
      emailValue: '',
      jenis_kelamin: '',
      nik_ktp: '',
      no_hp: '',
      no_wa: '',
      alamat: '',
      provinsi: '',
      kota: '',
      kecamatan: '',
      no_hp_keluarga: '',
      nama_keluarga: '',
      status_keluarga: '',
      layanan: [],
      area: '',
      error: false,
      suratPernyataan: undefined,
      selfiSuratPernyataan: undefined,
      fotoKTP: undefined,
      selfiKTP: undefined,
      fotoSKCK: undefined,
      fotoProfil: undefined,
      fotoSertifikat: undefined,
      required,
      email,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFilesize: 2,
        dictDefaultMessage: 'Taruh Foto Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
      },
    }
  },
  async mounted() {
    await this.getMitra()
  },
  methods: {
    /* eslint-disable arrow-parens */
    /* eslint-disable arrow-body-style */
    /* eslint-disable no-lonely-if */
    getMitra() {
      db.collection('mitra').doc(this.id).get().then(snapshot => {
        this.userData = snapshot.data()
      })
    },

    async formSubmitted() {
      db.collection('mitra').doc(this.id).set(this.userData).then(() => {
        this.$router.push(`/mitra/${this.id}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mengubah data mitra!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }, { merge: true })
    },

    dokumenError(file, message, xhr) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },

    async uploadSuratPernyataan(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/suratPernyataan.${filetype}`)
      await ref.put(file, metadata)

      this.userData.suratPernyataan = await ref.getDownloadURL()
    },
    async uploadSelfiSuratPernyataan(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/selfiSuratPernyataan.${filetype}`)
      await ref.put(file, metadata)

      this.userData.selfiSuratPernyataan = await ref.getDownloadURL()
    },
    async uploadFotoKTP(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoKTP.${filetype}`)
      await ref.put(file, metadata)

      this.userData.fotoKTP = await ref.getDownloadURL()
    },
    async uploadSelfiKTP(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/selfiKTP.${filetype}`)
      await ref.put(file, metadata)

      this.userData.selfiKTP = await ref.getDownloadURL()
    },
    async uploadFotoSKCK(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoSKCK.${filetype}`)
      await ref.put(file, metadata)

      this.userData.fotoSKCK = await ref.getDownloadURL()
    },
    async uploadFotoProfil(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoProfil.${filetype}`)
      await ref.put(file, metadata)

      this.userData.fotoProfil = await ref.getDownloadURL()
    },
    async uploadFotoSertifikat(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoSertifikat.${filetype}`)
      await ref.put(file, metadata)

      this.userData.fotoSertifikat = await ref.getDownloadURL()
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  .back {
    cursor: pointer;
  }
</style>
