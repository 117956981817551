var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"align-middle text-primary mr-2 mb-2 back",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('h3',[_vm._v("Edit Mitra")])],1),_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#28C76F","title":null,"subtitle":null,"shape":"square","finish-button-text":"Update Data","back-button-text":"Kembali","next-button-text":"Selanjutnya"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Informasi Pribadi","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"namaLengkap"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"namaLengkap","state":errors.length > 0 ? false:null,"placeholder":"Nama Lengkap"},model:{value:(_vm.userData.nama),callback:function ($$v) {_vm.$set(_vm.userData, "nama", $$v)},expression:"userData.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"Email Mitra"},model:{value:(_vm.userData.emailValue),callback:function ($$v) {_vm.$set(_vm.userData, "emailValue", $$v)},expression:"userData.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"jeniskelamin"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('b-form-radio',{staticClass:"mr-2",attrs:{"plain":"","value":"0"},model:{value:(_vm.userData.jenis_kelamin),callback:function ($$v) {_vm.$set(_vm.userData, "jenis_kelamin", $$v)},expression:"userData.jenis_kelamin"}},[_vm._v(" Laki Laki ")]),_c('b-form-radio',{attrs:{"plain":"","value":"1"},model:{value:(_vm.userData.jenis_kelamin),callback:function ($$v) {_vm.$set(_vm.userData, "jenis_kelamin", $$v)},expression:"userData.jenis_kelamin"}},[_vm._v(" Wanita ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NIK KTP","label-for":"nik"}},[_c('validation-provider',{attrs:{"name":"NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nik","state":errors.length > 0 ? false:null,"placeholder":"NIK KTP","type":"number"},model:{value:(_vm.userData.nik_ktp),callback:function ($$v) {_vm.$set(_vm.userData, "nik_ktp", $$v)},expression:"userData.nik_ktp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Layanan","label-for":"layanan"}},[_c('validation-provider',{attrs:{"name":"layanan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.vertikaloptions,"placeholder":"Layanan"},model:{value:(_vm.layanan),callback:function ($$v) {_vm.layanan=$$v},expression:"layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kode Area","label-for":"area"}},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area","state":errors.length > 0 ? false:null,"placeholder":"Kode Area","type":"number"},model:{value:(_vm.userData.layanan[0].area),callback:function ($$v) {_vm.$set(_vm.userData.layanan[0], "area", $$v)},expression:"userData.layanan[0].area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Informasi Kontak","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor HP","label-for":"nomorHP"}},[_c('validation-provider',{attrs:{"name":"Nomor HP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nomorHP","state":errors.length > 0 ? false:null,"placeholder":"Nomor HP","type":"number"},model:{value:(_vm.userData.no_hp),callback:function ($$v) {_vm.$set(_vm.userData, "no_hp", $$v)},expression:"userData.no_hp"}})],1),_c('small',{staticClass:"text-secondary mb-2"},[_vm._v("Format : 628xxxxxxxxx")]),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor WhatsApp","label-for":"nomorWhatsApp"}},[_c('validation-provider',{attrs:{"name":"Nomor WhatsApp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"nomorWhatsApp","state":errors.length > 0 ? false:null,"placeholder":"Nomor WhatsApp","type":"number"},model:{value:(_vm.userData.no_wa),callback:function ($$v) {_vm.$set(_vm.userData, "no_wa", $$v)},expression:"userData.no_wa"}})],1),_c('small',{staticClass:"text-secondary mb-2"},[_vm._v("Format : 628xxxxxxxxx")]),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat Lengkap","label-for":"alamat"}},[_c('validation-provider',{attrs:{"name":"Alamat Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"alamat","state":errors.length > 0 ? false:null,"placeholder":"Alamat Lengkap"},model:{value:(_vm.userData.alamat),callback:function ($$v) {_vm.$set(_vm.userData, "alamat", $$v)},expression:"userData.alamat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"provinsi"}},[_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"provinsi","state":errors.length > 0 ? false:null,"placeholder":"Provinsi"},model:{value:(_vm.userData.provinsi),callback:function ($$v) {_vm.$set(_vm.userData, "provinsi", $$v)},expression:"userData.provinsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kota/Kabupaten","label-for":"kota"}},[_c('validation-provider',{attrs:{"name":"Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kota","state":errors.length > 0 ? false:null,"placeholder":"Kota"},model:{value:(_vm.userData.kota),callback:function ($$v) {_vm.$set(_vm.userData, "kota", $$v)},expression:"userData.kota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kecamatan","label-for":"kecamatan"}},[_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kecamatan","state":errors.length > 0 ? false:null,"placeholder":"Kecamatan"},model:{value:(_vm.userData.kecamatan),callback:function ($$v) {_vm.$set(_vm.userData, "kecamatan", $$v)},expression:"userData.kecamatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Informasi Keluarga","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor HP Keluarga","label-for":"no_hp_keluarga"}},[_c('validation-provider',{attrs:{"name":"Nomor HP Keluarga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"no_hp_keluarga","state":errors.length > 0 ? false:null,"placeholder":"Nomor HP Keluarga","type":"number"},model:{value:(_vm.userData.keluarga.no_hp_keluarga),callback:function ($$v) {_vm.$set(_vm.userData.keluarga, "no_hp_keluarga", $$v)},expression:"userData.keluarga.no_hp_keluarga"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Keluarga","label-for":"nama_keluarga"}},[_c('validation-provider',{attrs:{"name":"Nama Keluarga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama_keluarga","state":errors.length > 0 ? false:null,"placeholder":"Nama Keluarga"},model:{value:(_vm.userData.keluarga.nama_keluarga),callback:function ($$v) {_vm.$set(_vm.userData.keluarga, "nama_keluarga", $$v)},expression:"userData.keluarga.nama_keluarga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status Keluarga","label-for":"status_keluarga"}},[_c('validation-provider',{attrs:{"name":"Status Keluarga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"status_keluarga","state":errors.length > 0 ? false:null,"placeholder":"Status Keluarga"},model:{value:(_vm.userData.keluarga.status_keluarga),callback:function ($$v) {_vm.$set(_vm.userData.keluarga, "status_keluarga", $$v)},expression:"userData.keluarga.status_keluarga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Data Pendukung","before-change":_vm.validationFormSocial}},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Penting! ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" Hanya upload data pendukung yang ingin diubah, jika tidak diubah maka tidak perlu diisi ")])]),_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto Surat Pernyataan"}},[_c('validation-provider',{attrs:{"name":"Foto Surat Pernyataan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropSuratPernyataan",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadSuratPernyataan,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.suratPernyataan = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto Selfi Bersama Surat Pernyataan"}},[_c('validation-provider',{attrs:{"name":"Foto Selfi Bersama Surat Pernyataan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropSelfiSuratPernyataan",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadSelfiSuratPernyataan,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.selfiSuratPernyataan = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto KTP"}},[_c('validation-provider',{attrs:{"name":"Foto KTP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropKTP",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadFotoKTP,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.fotoKTP = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto Selfi Bersama KTP"}},[_c('validation-provider',{attrs:{"name":"Foto Selfi Bersama KTP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropSelfiKTP",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadSelfiKTP,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.selfiKTP = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto SKCK / Surat Pernyataan SKCK Sementara"}},[_c('validation-provider',{attrs:{"name":"Foto SKCK / Surat Pernyataan SKCK Sementara"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropSKCK",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadFotoSKCK,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.fotoSKCK = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto Profil"}},[_c('validation-provider',{attrs:{"name":"Foto Profil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropProfil",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadFotoProfil,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.fotoProfil = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto Sertifikat"}},[_c('validation-provider',{attrs:{"name":"Foto Sertifikat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"dropSertifikat",attrs:{"id":"dropzone","options":_vm.dropzoneOptions},on:{"vdropzone-complete":_vm.uploadFotoSertifikat,"vdropzone-error":_vm.dokumenError,"vdropzone-removed-file":function($event){_vm.fotoSertifikat = undefined}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }